

.home-btn {
    background: #FFFFFF;
    box-shadow: 0px 5px 15px 2px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    white-space: nowrap;
    @apply flex justify-center items-center my-3 mx-2 py-6 px-4 tracking-widest;
    @include lg-width{
        font-size: 32px;
        line-height: 36px;
        @apply py-10 my-12;
    }
    .c1 {
        color: $color1;
    }
    .c2 {
        color: $color2;
    }
    .c3 {
        color: $color3;
    }
    .c4{
        color: $color4;
    }
    .c5{
        color: $second-color;
    }
}