.header {
    height: 67px;
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    z-index: 20;
    @apply flex justify-between items-center px-6;

}

.header-nav-btn {
    padding: 0.25rem 0.5rem;
    letter-spacing: 0.1em;

    &:hover {
        border-bottom: 1px solid #F6A21A;
        transition: 0.2s;
    }
}