.banner {
    background-image: url('../img/bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    height: 213px;


    @include lg-width {
        height: 484px;
    }

}

.article {
    padding-left: 5%;
    writing-mode: vertical-rl;
    letter-spacing: .3em;
    color: white;
    font-family: Noto Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    @include lg-width {
        font-size: 26px;
        line-height: 35px;
    }
    p {
        -webkit-margin-before: .2em;
        -webkit-margin-after: .2em;
    }
}