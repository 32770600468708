.pin-icon {
    &.red {
        filter: invert(66%) sepia(18%) saturate(854%) hue-rotate(314deg) brightness(86%) contrast(95%);
    }
}

.pin-icon-station-name {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;
    color: black;
}

.pin-icon-station-address {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    white-space: nowrap;
    color: #888888;
}

.pin-icon-route-name {
    color: #355F8B;
    white-space: nowrap;
    @apply mr-2;
}

.pin-here {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
}